import { DownloadURL } from "constants/download";
import React from "react";
import styled from "styled-components";
import { FluidImageData, BackgroundImageData } from "types";
import { OS, getDeviceOS } from "utils/detect-os";

import "@fontsource/jetbrains-mono/400.css";

interface IProps {
    featureImage: FluidImageData;
    camera: BackgroundImageData;
}

const Container = styled.section`
    display: flex;
    align-items: stretch;

    @media (max-width: 992px) {
        margin-top: -10px;
    }

    @media (min-width: 992px) {
        margin-top: -40px;
    }

    @media (min-width: 1900px) {
        margin-top: -40px;
    }
`;

const BackDropMobile = styled.div.attrs({})<{ backdropBackground: string }>`
    overflow: hidden;
    color: #0c4401;
    width: 100%;
    display: flex;
    align-items: center;
    background: rgb(18, 18, 18) url("${(props) => props.backdropBackground}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1000px 712px;
    flex-direction: column;
    height: calc(100dvh - 60px);

    @media (min-width: 992px) {
        display: none;
    }
`;

const HeadingMobile = styled.h1`
    font-weight: 700;
    font-family: "JetBrains Mono" !important;
    color: #fff !important;
    line-height: 110%;

    @media (max-width: 992px) {
        font-size: 25px;
    }

    @media (min-width: 992px) {
        font-size: 45px;
    }

    @media (min-width: 1400px) {
        font-size: 55px;
    }
`;

const ParagraphMobile = styled.p`
    font-weight: 500;
    font-size: 15px;
    color: rgb(181, 181, 181);

    @media (min-width: 332px) {
        font-size: 16px;
    }

    @media (min-width: 440px) {
        font-size: 18px;
    }

    @media (min-width: 768px) {
        font-size: 20px;
    }

    @media (min-width: 992px) {
        font-size: 24px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

const PictureMobile = styled.picture<{
    aspectRatio: { light: string };
}>`
    z-index: 1:
    & > img {
        aspect-ratio: ${(props) => props.aspectRatio.light} / 1;
    }
`;

const ImageMobile = styled.img`
    scale: 1;

    height: calc(100vh - 500px);
`;

const HeadingContainerMobile = styled.div.attrs({
    className: "text-center text-lg-start",
})`
    font-family: "JetBrains Mono";

    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        margin-top: 25px;
        width: 90%;
        align-items: center;
    }
`;

const HeadingInnerContainerMobile = styled.div.attrs({
    className: "text-center text-lg-start",
})`
    font-family: "JetBrains Mono";

    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        width: 90%;
        align-items: center;
    }

    @media (min-width: 992px) {
        width: 500px;
        gap: 10px;
    }

    @media (min-width: 1900px) {
        width: 580px;
        gap: 10px;
    }
`;

const ImageContainerMobile = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        width: 100%;
        justify-content: center;
    }
`;

const BackDropDesktop = styled.div.attrs({})<{ backdropBackground: string }>`
    overflow: hidden;
    color: #0c4401;
    width: 100%;
    display: none;
    align-items: center;
    background: rgb(18, 18, 18) url("${(props) => props.backdropBackground}");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1000px 712px;

    @media (max-width: 992px) {
        flex-direction: column;
        height: calc(100vh - 60px);
    }

    @media (min-width: 992px) {
        flex-direction: row;
        height: calc(100vh - 60px);
        justify-content: center;
        gap: 300px;
        display: flex;
    }

    @media (min-width: 1900px) {
        flex-direction: row;
        height: calc(100vh - 80px);
        justify-content: center;
        gap: 250px;
        display: flex;
    }
`;

const HeadingDesktop = styled.h1`
    font-weight: 700;
    font-family: "JetBrains Mono" !important;
    color: #fff !important;
    line-height: 110%;

    @media (max-width: 992px) {
        font-size: 25px;
    }

    @media (min-width: 992px) {
        font-size: 45px;
    }

    @media (min-width: 1400px) {
        font-size: 55px;
    }
`;

const ParagraphDesktop = styled.p`
    font-weight: 500;
    font-size: 15px;
    color: rgb(181, 181, 181);

    @media (min-width: 332px) {
        font-size: 16px;
    }

    @media (min-width: 440px) {
        font-size: 18px;
    }

    @media (min-width: 768px) {
        font-size: 20px;
    }

    @media (min-width: 992px) {
        font-size: 24px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;

const PictureDesktop = styled.picture<{
    aspectRatio: { light: string };
}>`
    z-index: 1:
    & > img {
        aspect-ratio: ${(props) => props.aspectRatio.light} / 1;
    }
`;

const ImageDesktop = styled.img`
    scale: 1;

    @media (max-width: 992px) {
        height: calc(100vh - 280px);
    }

    @media (min-width: 992px) {
        height: calc(100vh - 280px);
    }

    @media (min-width: 1900px) {
        height: 650px;
    }
`;

const HeadingContainerDesktop = styled.div.attrs({
    className: "text-center text-lg-start",
})`
    font-family: "JetBrains Mono";

    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        margin-top: 25px;
        width: 90%;
        align-items: center;
    }
`;

const HeadingInnerContainerDesktop = styled.div.attrs({
    className: "text-center text-lg-start",
})`
    font-family: "JetBrains Mono";

    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        width: 90%;
        align-items: center;
    }

    @media (min-width: 992px) {
        width: 500px;
        gap: 10px;
    }

    @media (min-width: 1900px) {
        width: 580px;
        gap: 10px;
    }
`;

const ImageContainerDesktop = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
        width: 100%;
        justify-content: center;
        margin-top: 50px;
    }
`;

const CtaButton = styled.button`
    z-index: 2;
    border: none;
    background-color: rgb(1, 183, 47);
    padding: 16px 12px;
    color: #fff;
    border-radius: 15px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 20px;
    font-family: Gilroy;
    width: 230px;
    position: relative;
    overflow: hidden;
    align-items: center;
    letter-spacing: 0.8px;
    min-height: 60px;

    &::before {
        content: " ";
        background: #7df264;
        filter: blur(20px);
        position: absolute;
        left: 0;
        width: 12px;
        top: -100%;
        height: 400%;
        animation: shine 4s linear infinite;
    }

    @keyframes shine {
        0% {
            left: -50%;
            rotate: 45deg;
        }
        100% {
            left: 200%;
            rotate: 45deg;
        }
    }

    @media (max-width: 992px) {
        width: 80%;
        padding: 10px 12px;
        border-radius: 10px;
        font-size: 16px;
        margin-bottom: 40px;
    }

    @media (min-width: 992px) {
        padding: 12px 12px;
        width: 360px;
    }
`;

const DownloadCTA: React.FC = () => {
    const handleClick = () => {
        const os = getDeviceOS();

        const androidDownloadLink = DownloadURL.googlePlay;
        const iosDownlaodLink = DownloadURL.appStore;
        const downloadSectionID = "download";

        if (os === OS.Android) {
            window.location.href = androidDownloadLink;
        } else if (os === OS.IOS) {
            window.location.href = iosDownlaodLink;
        } else {
            const downloadSection = document.getElementById(downloadSectionID);
            if (downloadSection) {
                downloadSection.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        }
    };

    return (
        <CtaButton
            id="take-control-download-button-hero-section"
            onClick={handleClick}
        >
            Download
        </CtaButton>
    );
};

export default function HeroSectionTakeControl(props: IProps) {
    return (
        <Container>
            <BackDropDesktop backdropBackground={props.camera.fixed.src}>
                <HeadingContainerDesktop>
                    <HeadingInnerContainerDesktop>
                        <HeadingDesktop>
                            Don’t trust Big Tech with your photos
                        </HeadingDesktop>
                        <ParagraphDesktop>
                            Store your memories, privately and securely, with
                            Ente.
                        </ParagraphDesktop>
                        <DownloadCTA />
                    </HeadingInnerContainerDesktop>
                </HeadingContainerDesktop>
                <ImageContainerDesktop>
                    <PictureDesktop
                        aspectRatio={{
                            light: props.featureImage.fluid.aspectRatio,
                        }}
                    >
                        <ImageDesktop
                            fetch-priority="high"
                            srcSet={props.featureImage.fluid.srcSetWebp}
                            alt="Ente App Screenshot"
                        />
                    </PictureDesktop>
                </ImageContainerDesktop>
            </BackDropDesktop>
            <BackDropMobile backdropBackground={props.camera.fixed.src}>
                <HeadingContainerMobile>
                    <HeadingInnerContainerMobile>
                        <HeadingMobile>
                            Don’t trust Big Tech with your photos
                        </HeadingMobile>
                        <ParagraphMobile>
                            Store your memories, privately and securely, with
                            Ente.
                        </ParagraphMobile>
                    </HeadingInnerContainerMobile>
                </HeadingContainerMobile>
                <ImageContainerMobile>
                    <PictureMobile
                        aspectRatio={{
                            light: props.featureImage.fluid.aspectRatio,
                        }}
                    >
                        <ImageMobile
                            fetch-priority="high"
                            srcSet={props.featureImage.fluid.srcSetWebp}
                            alt="Ente App Screenshot"
                        />
                    </PictureMobile>
                </ImageContainerMobile>
                <DownloadCTA />
            </BackDropMobile>
        </Container>
    );
}
